html,
body,
.layout {
  width: 100%;
  height: 100%;
}

nav.menu ul {
  list-style-type: none;
  margin: 0 0 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid grey;
}

nav.menu li {
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 20px;
  border-left: 1px solid grey;
}

nav.menu li:first-child {
  padding-left: 0;
  border-left: 0;
}

.float-left {
  float: left;
}

table {
  margin-right: 30px;
  border-spacing: 0;
}

table td,
table th {
  border: 1px grey solid;
  padding-left: 5px;
  padding-right: 5px;
}

div.controls input {
  margin-right: 20px;
  margin-left: 5px;
}

div.loan-decision {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.loan-decision span {
  margin-right: 20px;
}

.red {
  color: #ff0000;
}

.green {
  color: #029d02;
}

.blue {
  color: #0000ff;
}

table th,
table td {
  font-size: 10px;
}
